export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        coachings :'', 
        coaching :'', 
        coaching_teachers :'', 
    },
    
    getters : {
        coachings: (state )=> state.coachings,
        coaching: (state )=> state.coaching,
        coaching_teachers: (state )=> state.coaching_teachers,
    },


    mutations : { 
        setcoachings :(state,coachings ) => (state.coachings = coachings), 
        setcoaching :(state,coaching ) => (state.coaching = coaching),
        setcoaching_teachers :(state,coaching_teachers ) => (state.coaching_teachers = coaching_teachers),
    },

    actions : {

        async get_coachings( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_coachings')
            return new Promise((resolve, reject) => {
            axios.post("help/coachings?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setcoachings', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_coaching( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_coaching' + id)
            return new Promise((resolve, reject) => {
            axios.post("help/coachings/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setcoaching', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_coaching_teachers( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_coaching_teachers')
            return new Promise((resolve, reject) => {
            axios.post("help/coachings/teachers/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setcoaching_teachers', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async forward_coaching({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'forward_coaching' + id)
            return new Promise((resolve, reject) => {
                axios.post(`help/coachings/forward/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('setcoachings', response.data.res)
                        toast.success("Assessment Forwarded Successfully")
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });
        },


        async delete_coaching({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_coaching' + id)
            return new Promise((resolve, reject) => {
                axios.post(`help/coachings/delete/${id}`, data)
                .then(response => {
                    
                    commit('setloader', false)
                    if (response.data) {
                        commit('setcoachings', response.data.res)
                        toast.success("Coaching Request Deleted Successfully")
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });
        },
    }
}