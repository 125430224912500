export const namespaced = true
import store from '@/store'
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        feedback :'',
        feedbacks :'', 
    },
    
    getters : {
        feedback: (state )=> state.feedback,
        feedbacks: (state )=> state.feedbacks,
    },


    mutations : { 
        setfeedback :(state,feedback ) => (state.feedback = feedback),
        setfeedbacks :(state,feedbacks ) => (state.feedbacks = feedbacks),  
    },

    actions : {

        async send_feedback_request( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'send_feedback_request')
            return new Promise((resolve, reject) => {
            axios.post("/global/feedbacks/send/"+id, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                commit('setfeedback', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async read_feedback_messages( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'read_feedback_messages')
            return new Promise((resolve, reject) => {
            axios.post("/global/feedbacks/read/"+id, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                commit('setfeedback', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_feedbacks( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_feedbacks')
            return new Promise((resolve, reject) => {
            axios.post("/global/feedbacks/pending?page=" + page, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                commit('setfeedbacks', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async new_feedback_message( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'new_feedback_message')
            return new Promise((resolve, reject) => {
            axios.post("/global/feedbacks/new", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    commit('setfeedback', response.data.res)
                    toast.success('Feedback Sent Successfully')
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    },

    
}

