export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        assessments :'', 
        assessment :'', 
        assessment_teachers :'', 
    },
    
    getters : {
        assessments: (state )=> state.assessments,
        assessment: (state )=> state.assessment,
        assessment_teachers: (state )=> state.assessment_teachers,
    },


    mutations : { 
        setassessments :(state,assessments ) => (state.assessments = assessments), 
        setassessment :(state,assessment ) => (state.assessment = assessment),
        setassessment_teachers :(state,assessment_teachers ) => (state.assessment_teachers = assessment_teachers),
    },

    actions : {

        async get_assessments( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_assessments')
            return new Promise((resolve, reject) => {
            axios.post("help/assessments?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setassessments', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_assessment( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_assessment' + id)
            return new Promise((resolve, reject) => {
            axios.post("help/assessments/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setassessment', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_assessment_teachers( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_assessment_teachers')
            return new Promise((resolve, reject) => {
            axios.post("help/assessments/teachers/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setassessment_teachers', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async forward_assessment({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'forward_assessment' + id)
            return new Promise((resolve, reject) => {
                axios.post(`help/assessments/forward/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('setassessments', response.data.res)
                        toast.success("Assessment Forwarded Successfully")
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });
        },
    }
}