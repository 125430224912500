import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import SecureLs from 'secure-ls'
const secure = new SecureLs({isCompression:false});
import auth  from './modules/auth'
import utils  from './modules/utils'
import coachings  from './modules/coachings'
import assessments  from './modules/assessments'
import notifications  from './modules/notifications'
import feedback  from './modules/feedback'

export default createStore({
  modules: {
    auth, 
    utils,
    coachings,
    assessments,
    notifications,
    feedback,
  },
    
  plugins: [createPersistedState(
    {
      key : 'educan.com',
      paths: ['auth','utils'],
      storage:{
         getItem : (key) => secure.get(key),
         setItem: (key, value) => secure.set(key, value),
         removeItem: (key) => secure.remove(key)
      }
    }
  )],
  
})
