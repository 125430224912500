import store from '@/store'

const authGuards = (to, from, next) => {
    if(localStorage.getItem('educanToken' + store.getters.authuser.id) != null && store.getters.authuser != null && store.getters.authuser.id ){
      if (store.getters.authuser.role == 'admin' || store.getters.authuser.role == 'help center') {
        return next()
      } else {
        return next({ name: 'login' })
      }
    }else{
        return next({ name: 'login' })
    }
};


let routes = [{
  path: "/",
  props: true,
  beforeEnter: authGuards,
  component: () => import("@/views/app/main.vue"),
  children: [
    // ============================================== Dashboard ============================================
    {
      path: "/",
      name: 'dashboard',
      props: true,
      beforeEnter: authGuards,
      component: () => import("@/views/app/dashboard/main.vue"),
      meta: {
        title: 'Dashboard',
      },
    },


    // ============================================= Education Settings ===================================
    {
      path: "/help_center/",
      name: 'help_center',
      props: true,
      beforeEnter: authGuards,
      component: () => import("@/views/app/requests/main.vue"),
      children: [
        {
          path: "coaching_requests",
          name: 'coaching_requests',
          props: true,
          beforeEnter: authGuards,
          component: () => import("@/views/app/requests/coaching.vue"),
          meta: {
            title: 'Coaching Requests',
          },
        },

        {
          path: "assessment_requests",
          name: 'assessment_requests',
          props: true,
          beforeEnter: authGuards,
          component: () => import("@/views/app/requests/assessments.vue"),
          meta: {
            title: 'Assessment Requests',
          },
        },

        {
          path: "general_requests",
          name: 'general_requests',
          props: true,
          beforeEnter: authGuards,
          component: () => import("@/views/app/requests/general.vue"),
          meta: {
            title: 'General Requests',
          },
        },
      ]
    },

    // ===================================== Help =================================================
    {
      path: "/feedbacks",
      name: '',
      props: true,
      beforeEnter: authGuards,
      component: () => import("@/views/app/feedbacks/main.vue"),
      children: [
        {
          path: "",
          name: 'feedbacks',
          props: true,
          beforeEnter: authGuards,
          component: () => import("@/views/app/feedbacks/feedbacks.vue"),
          meta: {
            title: 'Feedbacks',
          },
        },
      ]
    },

    // ===================================== User Account Settings =================================
    {
      path: "/account",
      name: 'account',
      props: true,
      beforeEnter: authGuards,
      component: () => import("@/views/app/account/main.vue"),
      children: [
        {
          path: "/profile",
          name: 'profile',
          props: true,
          beforeEnter: authGuards,
          component: () => import("@/views/app/account/profile.vue"),
          meta: {
            title: 'Profile',
          },
        },
      ]
    },

    {
      path: "/notifications",
      name: '',
      props: true,
      beforeEnter: authGuards,
      component: () => import("@/views/app/notifications/main.vue"),
      children: [
        {
          path: "",
          name: 'notifications',
          props: true,
          beforeEnter: authGuards,
          component: () => import("@/views/app/notifications/notifications.vue"),
          meta: {
            title: 'notifications',
          },
        },
      ]
    },

  ]
}]
export default routes;